<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      v-c-emit-root-event:toggle-sidebar-mobile
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      v-c-emit-root-event:toggle-sidebar
    />

    <CHeaderNav>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">{{$t('home')}}</CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
   
    <CHeaderNav class="ml-auto" v-show="showDropdown" >
      <!-- <TheHeaderDropdownNotif /> -->
       <CDropdown
    color="primary"
    :toggler-text="$t('language')" 
    class="m-2"
    ref="myDropdown"

  >
    <CDropdownItem
    v-for="language in countryCodes"
    :key="language.abv"
    
    @click="changeCountry(language)"
    
    >
    <img :src="language.imageSrc" alt="" width="30" class="flag-icon" style="margin-right: 1rem;">

      {{ $t(language.abv) }}
  </CDropdownItem>
  </CDropdown> 
    </CHeaderNav>

    <CSubheader class="px-3">
      <div class="mijas">
        <CRow>
          <CCol md="8" lg="8" xs="12">
            <span class="mijasL">
              <CBreadcrumbRouter class="border-0" />
            </span>
          </CCol>
        </CRow>
      </div>

      <div style="clear: both"></div>
    </CSubheader>
  </CHeader>
</template>

<script>
// import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
// import Dialog from "primevue/dialog";
// import myclock from "../utils/Clock";
// import Dropdown from "primevue/dropdown";
import BlockItem from '../components/BlockItem.vue';
import BlockInfoSelect from '../components/BlockInfoSelect.vue'
import config from '../../config'
export default {
  name: "TheHeader",
  components: {
    // TheHeaderDropdownNotif,
    // Dialog,
    // myclock,
    // Dropdown,
    BlockItem,
    BlockInfoSelect
  },

  data() {
    return {
      selectedCountry: null,
      countryCodes: [],
      
      showDropdown: true,
      keyCountry: 1

    };
  },

  computed: {},
  created() {

  },
  async mounted() {
    this.setDropdownValues() 
    const dropdownMenu = this.$el.querySelector('.dropdown-menu');
  
    if (dropdownMenu) {
      dropdownMenu.style.maxHeight = '200px';
      dropdownMenu.style.overflowY = 'auto';
}
  },


  methods: {
    changeCountry(value) {
      let user = JSON.parse(localStorage.getItem("i18n")).translations;
      this.$i18n.i18next.changeLanguage(value.abv)
      // this.$i18n.i18next.addResourceBundle(value.abv, "translation", user[value.abv]);
      let storage = JSON.parse(localStorage.getItem('i18n'))
      storage.language = value.abv;
      localStorage.setItem('i18n', JSON.stringify(storage)) 
      localStorage.setItem('selectedLanguage',value.abv )
      this.refresh();
    },

    updateValue(e) {
      console.log('update value', e)
    }, 
    changeValue(e) {
      console.log(e)
    },
    async setDropdownValues() {
      let user = JSON.parse(localStorage.getItem("i18n"));
      if (!user) {
        localStorage.removeItem("user");
        location.href = '/#/login';
      } else {
        user = user.translations
   
      let countries = Object.keys(user);
      
      if (countries.length > 0) {
        this.showDropdown = true;
      } 
      
      let key = 0;
      

      countries.forEach(country => {
        let icon = `https://rmxd-integration-rmxd-prod.ext.ocp.prod.lafargeholcim-go.com/images/languages/${country}.png`;
        this.countryCodes.push({ _key: key, abv: country, imageSrc: icon});
      }); 
      // this.countryCodes.push({ _key: "dev", abv: "dev", imageSrc: "dev"});

      
      }
      
    },
    refresh() {
      this.process = true;
      if (this.$route.query.id) {
        this.$router.push({
          path:
            this.$router.history.current.path +
            "?id=" +
            this.$route.query.id +
            "&t" +
            new Date().getMilliseconds(),
          force: true,
        });
      } else {
        this.$router.push({
          path:
            this.$router.history.current.path +
            "?t" +
            new Date().getMilliseconds(),
          force: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.mijas {
  height: 45px;
  /* background-color: white; */
  width: 100%;
}
.mijasR {
  /* background-color: White; */
  padding: 10px;
  padding-right: 10px;
}
.mijasL {
  width: 50%;
  float: left;
}

.planSelector {
  width: 200px;
  margin-left: 10px;
  margin-right: 20px;
}
.dropdown-menu {
  height: 200px !important;
  overflow: auto ;
}

</style>